import axios, { AxiosRequestConfig } from 'axios'
import {WEB_API} from '../api'
import moment from 'moment'
import {useGlobalStore} from '../stores/globalStore'

type Config = {
    [key: string]: any
}

interface Options {
    cancelToken: any
}

const _promises = {}

axios.defaults.headers.common['Accept'] = 'application/json, text/javascript'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.withCredentials = false
axios.defaults.timeout = 1000 * 60 * 5

function $axios(options: AxiosRequestConfig<any>, config?: Config) {
    const { method, data } = options
    if (config) {
        Object.assign(options, config)
    }
    if (method === 'get') {
        options.params = data;
    } else {
        options.data = data;
    }
    // if (method !== 'get' && method !== 'delete') {
    //     options.data = data;
    // } else {
    //     options.params = data;
    // }
    const axiosInstance = axios.create()
    axiosInstance.interceptors.response.use(
        response => {
            if (response.headers.date) {
                const date = moment(response.headers.date).valueOf()
                const timeDiff = date - Date.now()
                console.log(response.headers.date, timeDiff, date, Date.now(), 'timeDiff')
                const store = useGlobalStore()
                store.setServerTimeDiff(timeDiff)
            }
            if (response.data.code !== 0) {
                if (response.data.code === 101) {
                } else {
                }
            }
            return response.data ? response.data : {};
        },
        error => {
            // console.log(error, 919191)
            // if (error && error.response.status === 401) {
            //     return
            // }
            return Promise.reject(error)
        }
    );
    axiosInstance.interceptors.request.use(
        config => {
            const store = useGlobalStore()
            console.log(store.authorization, 888888)
            if (store.authorization) {
                config.headers['Authorization'] = store.authorization
                config.headers['Content-Type'] = 'application/json;charset=UTF-8'
            }
            if (options.responseType) {
                config.responseType = 'blob'
            }
            return config
        },
        error => {
            // throw new Error(error);
        },
    );
    return axiosInstance(options)
}

export const $ajax = function (namespace: string | number, options: Options, config?: Config, url?: string ) {
    // @ts-ignore
    const apiOptions = url ? {method: 'get', url} : WEB_API[namespace](options)
    console.log(apiOptions, 99999999)
    let abort = () => {}
    if (namespace) {
        const CancelToken = axios.CancelToken
        apiOptions.cancelToken = new CancelToken(cancel => {
            abort = cancel
        });
        // @ts-ignore
        _promises[namespace] = abort
    }
    return $axios(apiOptions, config)
}

