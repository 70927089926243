export const contractAddress = process.env['VUE_APP_NODE_ENV'] === 'production' ? {
    BOX: '0xe5Fa1b85037760749ca89310FAE9571e17514Dd3',
    CAT: '0x4E98F1C70B7FdfcB6DAd917fcEE7F7a2dcdd414e'
} : {
    BOX: '0xa66C04aF32CF3B720daff273720eDc695Dc1931E',
    CAT: '0x0FBd1B99dcC3880EbBf3a61CC186eBCBA7dC93D6'
}

export const mainChainName = process.env['VUE_APP_NODE_ENV'] === 'production' ? 'Polygon' : 'Goerli'
export const mainChainDisplayName = process.env['VUE_APP_NODE_ENV'] === 'production' ? 'Polygon' : 'Goerli'
export const mainChainId = process.env['VUE_APP_NODE_ENV'] === 'production' ? '0xa4b1' : '0x5'

export const netWork = {
    Polygon: {
        chainId: '',
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ["https://polygon-mainnet.infura.io"],
        blockExplorerUrls: ["https://polygonscan.com/"]
    },
    ArbitrumOne: {
        chainId: `0xa4b1`,
        chainName: 'Arbitrum One',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ["https://arb1.arbitrum.io/rpc"],
        blockExplorerUrls: ['https://arbiscan.io']
    },
    Goerli: {
        chainId: `0x5`,
        chainName: 'Goerli',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ["https://goerli.infura.io/v3/0d6e7661f3e04cf8b9d94b49b0df2c7b"],
        blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io']
    }
}

export const website = process.env['VUE_APP_NODE_ENV'] === 'production' ? 'https://www.etheroot.com' : 'https://testnet.etheroot.com'

// export const supportedWallets = {
//     METAMASK: {
//         id: 'injected',
//         connector: null,
//         name: 'MetaMask',
//         icon: metamaskIcon,
//         description: 'Easy-to-use browser extension.',
//         href: null,
//         color: '#E8831D'
//     },
//     walletconnect: {
//         id: 'custom-walletconnect',
//         connector: walletConnect,
//         type: 'custom',
//         name: 'WalletConnect',
//         icon: walletconnectIcon,
//         description: 'Scan with WalletConnect to connect',
//         href: null,
//         color: '#4196FC',
//         mobile: true,
//         display: {
//             name: "WalletConnect"
//         },
//         options: {
//             chainId: 42161,
//             rpc: {
//                 [42161]: 'https://arb1.arbitrum.io/rpc'
//             },
//             qrcode: true
//         }
//     },
//     tokenpocket: {
//         id: 'injected',
//         connector: null,
//         name: 'TokenPocket',
//         icon: tokenpocketIcon,
//         description: 'Easy-to-use browser extension.',
//         href: null,
//         color: '#E8831D'
//     },
//     trust: {
//         id: 'injected',
//         connector: null,
//         name: 'Trust Wallet',
//         icon: trustIcon,
//         description: 'Easy-to-use browser extension.',
//         href: null,
//         color: '#E8831D'
//     },
//     math: {
//         id: 'injected',
//         connector: null,
//         name: 'Math Wallet',
//         icon: mathIcon,
//         description: 'Easy-to-use browser extension.',
//         href: null,
//         color: '#E8831D'
//     }
// }
