import {ref} from 'vue'
import {$ajax} from '../utils/ajax'
import {showFailToast, showLoadingToast} from 'vant'
import {useGlobalStore} from '@/stores/globalStore'

export const useAjax = (api: string, initData?: any, url?: string, notLoading?: boolean) => {
    const data = ref(initData || {})
    const error = ref(null)
    const fetch = async (params: any) => {
        const store = useGlobalStore()
        let loading
        if (!notLoading) {
            loading = showLoadingToast({
                message: '加载中...',
                duration: 1000 * 60 * 5
            })
        }

        const res = await $ajax(api, params, {}, url).then(res => res).catch(err => err)
        if (!notLoading) {
            loading?.close()
        }
        if (res.code === 200) {
            data.value = res.data
        } else {
            if (res.code === 400003 || res.code === 400005 ) {
                // store.setAccount('')
                store.setAuthorization('')
            }
            // if (!res.data) {
            //     showFailToast({
            //         message: res.msg,
            //         position: 'top',
            //         duration: 3000
            //     })
            // }
        }
        return res
    }
    return [data, fetch, error]
}
