import Home from '@/pages/home.vue'
import WhitePaper from '@/pages/white-paper.vue'
import Register from '@/pages/register.vue'
// import Test from '@/pages/test.vue'
// import Test1 from '@/pages/test--.vue'
import Bind from '@/pages/bind.vue'
export const routes = [
    { path: '/', component: Home },
    { path: '/White-paper/:name' , component: WhitePaper},
    { path: '/register', component: Register},
    // { path: '/test', component: Test},
    // { path: '/test1', component: Test1},
    { path: '/bind', component: Bind}
]
