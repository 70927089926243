export default {
    common: {
        t0: '操作成功',
        t1: '詳情',
        t2: '鑄造',
        t3: '全選',
        t4: '圖片',
        t5: '請輸入提示詞',
        t6: '請選擇作品比例',
        t7: '創建成功',
        t8: '連接錢包',
        t9: '請連接錢包',
        t10: '確認',
        t11: '取消',
        t12: 'ETHEROOT專注於以太坊亞洲WEB3項目首發打新，ARB網DEFI頭名。 NFT首發，AIGC藝術首發，MEME首發，PREIEO輪首發，機遇對了人就發了。',
        t13: '<p>ETHEROOT家族邀請函</p><p>連接錢包才能確認好友關係</p>',
        t14: '"複製邀請連結並轉發給朋友，可獲得大量平臺獎勵"'
    },
    header: {
        t0: '首頁',
        t1: '會員',
        t2: '項目',
        t3: '3D',
        t4: 'AIGC',
        t5: '我的',
        t6: '邀請',
        t7: '免費領'
    },
    home: {
        t0: 'ETHEROOT',
        t1: 'Web3第一個AI與區塊鏈結合的應用平臺',
        t2: '會員',
        t3: '專案啟動平臺',
        t4: 'AI繪畫',
        t5: '1.參與平臺項目<br/>2.獲得token空投<br/>3.體驗AI繪畫',
        t6: '1.免費參與的打新平臺<br/>2.NFT+token多種獎勵',
        t7: '1.先進的AI繪畫引擎<br/>2.不限制繪畫次數',
        t8: '前往',
        t9: '路線圖',
        t10: '平臺基礎能力搭建',
        t11: '開通Pass會員功能,<br/>開啟MEME發行功能',
        t12: '上線NFT發售市場',
        t13: '多種token空投<br/>開放社區治理',
        t14: '2023二季度',
        t15: '2023三季度',
        t16: '2023四季度',
        t17: '2024一季度',
        t18: '<p>為了響應香港的新政策，並全力支持web3.0的發展，Etheroot項目由全球web3精英共同發起。 該項目基於ETH最先進的Layer2 DEFI科技，同時應用AIGC科技，加密、NFT和區塊鏈的完美結合。 它不僅充分發揮了ETH的最大優勢，而且為項目的多元化、長期化、規模化發展奠定了堅實的基礎。</p>' +
            '<p>該項目充分集成了以太坊EVM和二層網絡、透明的帳戶隱私協定、去中心化的NFT帳戶系統、完善的二層網絡工具包、一鍵創建專屬數位資產、可信的交易證明，以及支持通過各種錢包訪問和使用。</p>' +
            '<p>該項目的初始階段受到了Web3世界和以太坊生態的高度關注，並吸引了多個鯨魚地址的注資和支持。 我們將盡最大努力搶佔香港網絡3.0和加密貨幣行業的制高點，貢獻我們的力量。</p>'
    },
    pass: {
        t0:'單價',
        t1:'規則描述',
        t2:'open in',
        t3:'opening',
        t4: 'Upcoming',
        t5: 'end',
        t6: 'EtheRootMemeClub Membership NFT（ERM）',
        t7: '<p>1.ERM本身是Etheroot發行的藝術品NFT系列。</p>' +
            '<p>2.ERM作為Etheroot會籍唯一標識NFT，持有即可參與從本平臺發起建設的模因項目並享受多重平臺福利。</p>' +
            '<p>3.ERM可疊加持有，持有數量越多，可享受權益及預期的回報越豐厚。</p>' +
            '<p>4.ERM按期發售，每期發售價格將較前一期有所上浮。</p>'
    },
    launchpad: {
        t0: '開放項目',
        t1: '即將開始',
        t2: '項目關閉',
        t3: '每人每天票數限制<span>{0}票</span>',
        t4: '項目名稱',
        t5: 'Total Token',
        t6: 'Progress',
        t7: '項目詳情',
        t8: '開放時間',
        t9: 'Token總量',
        t10: '結束時間',
        t11: '分配總量',
        t12: '領取時間',
        t13: '是否空投',
        t14: '空投數量',
        t15: 'Rate',
        t16: '空投條件',
        t17: '團隊介紹',
        t18: '合約地址',
        t19: '推特',
        t20: '電報',
        t21: '您還沒有ERM？',
        t22: '立即前往',
        t23: '可鑄造',
        t24: '項目詳情',
        t25: '開放時間',
        t26: 'Token總量',
        t27: '結束時間',
        t28: '分配總量',
        t29: '領取時間',
        t30: '是否有空投',
        t31: 'NFTs',
        t32: '空投數量',
        t33: 'Rate',
        t34: '空投條件',
        t35: '團隊介紹',
        t36: '合約地址',
        t37: '推特',
        t38: '電報',
        t39: '是',
        t40: '否',
        t41: '可領取'
    },
    produce: {
        t0: '描述詞',
        t1: '不同場景,順序,措辭會影響最終結果',
        t2: '藝術風格',
        t3: '藝術流派',
        t4: '作品比例',
        t5: '高級設定（可選）',
        t6: '創作',
        t7: '請輸入提示詞',
        t8: '請選擇比例',
        t9: '不指定',
        t10: '二次元',
        t11: '動漫風',
        t12: '肖像',
        t13: '點數藝術',
        t14: '亞洲風',
        t15: '攝影',
        t16: '油畫',
        t17: '3A畫作',
        t18: '電影感',
        t19: '賽博朋克',
        t20: '皮克斯',
        t21: '卡通渲染',
        t22: '達文西',
        t23: '梵古',
        t24: '莫奈',
        t25: '畢卡索',
        t26: '徐悲鴻',
        t27: '永井博',
        t28: '瑪麗安北',
        t29: '方圖',
        t30: '豎圖',
        t31: '橫圖',
        t32: '出圖清晰度',
        t33: '表情變化',
        t34: '進階玩法'
    },
    account: {
        t0: '累計領取token'
    },
    luck: {
        t0: '已領取',
        t1: '固定產量',
        t2: '天',
        t3: '日週期產量',
        t4: '邀請產量',
        t5: '當日可領取',
        t6: '領取',
        t7: '已領取',
        t8: '幣種介紹',
        t9: 'ETHEROOT CommemorativeToken。 社區壯大的證明代幣。 ETHROOT生態福利領取憑證，投票，社區建設及更多。',
        t10: '總量',
        t11: '持有地址數量',
        t12: '全網日產量',
        t13: '費用',
        t14: '免費',
        t15: '領取管道',
        t16: '自動獲得固定產量，每日點擊獲得全網日產量和邀請產量。',
        t17: '分享',
        t18: '分享連結邀請更多用戶可提高日產量。',
        t19: '馬上分享',
        t20: '說明',
        t21: `<p>固定產量為首次點擊獲取按鈕後，每日固定的產出，無論您未來是否每日點擊，您的地址都會每日接收到固定產量的Token。</p>
              <p>當前固定產量為100枚每天。</p>
              <p>日週期產量需要每日點擊獲取，才能獲得的額外產出量。 日週期產量在每個自然日切換後產生，您需要每個自然日任意時間手動採集。 如果當日沒有採集，則該產量不再新增和積累。 您必須在下一次點擊獲取按鈕之後，才獲得當天的日週期產量，並且重新積累下一個日週期產量。</p>
              <p>當前日週期產量為100枚每天。</p>
              <p>舉例：用戶Alice在第一天訪問了Etheroot，並且首次點擊採集按鈕。 Alice在第二天，第三天都忘記收集自己的礦產，第四天Alice訪問了收集頁，並點擊了收集按鈕，此時Alice收集到：第1,2,3,4天的固定產量和第4天的日週期產量。 之後日產量重新開始計算。</p>
              <p>邀請產量為分享本地址的邀請連結給其他用戶，用戶使用您的邀請連結訪問Etheroot並使用任意以太網區塊鏈錢包登入官網後，記錄為一次有效邀請。</p>
              <p>邀請產量舉例：</p>
              <p>Alice邀請了Bob，Bob邀請了Camel，Camel邀請了David，David邀請了Eva，Eva邀請了Frank，Frank邀請了George，George邀請了Hellen.</p>
              <p>Alice當日的邀請產量為：100+20+10+5+4+3+2+1=145。</p>
              <p>如果</p>
              <p>Alice還邀請了Bill，Bill邀請了Celine，Celine邀請了Daniel，Daniel邀請了Ember，Ember邀請了Fisher，Fisher邀請了Garry，Garry邀請了Hudson.</p>
              <p>Alice當日的邀請產量為：145+145=290。</p>
              <p>邀請產量和日週期產量採集管道一致，邀請產量在每個自然日切換後可以採集。 當日邀請產量如果忘記領取，邀請採礦將暫停，直至再次點擊採集按鈕後重新收集當日邀請產量。</p>
              <p>Token總量2,015,730,000枚</p>
              <p>初始全網日產量上限2,015,730枚</p>
              <p>在全網日總產量上限達到後，每日領取先到先得。</p>
              <p>已採集量每次達到剩餘總量的50%時，全網日產量减半。</p>           
              <p>示例：</p>
              <p>當全網已採集量達到總量50%（1007865000）時，全網日產量上限减半至1,007,865枚。</p>
              <p>當全網已採集量達到剩餘總量的50%時（1511797500）時，全網日產量上限再次减半至503,932.5枚。</p>`,
        t22: `<p>1.我需要使用什麼途徑參與？</p>
              <p>您需要擁有一個ETH錢包地址，使用任意錢包軟件訪問Etheroot。</p>
              <p>選擇connect wallet以當前地址登入Etheroot。</p>
              <p>2.我使用錢包地址簽名連接ETHEROOT，安全嗎？</p>
              <p>非常安全！</p>
              <p>任何ETH，Arbitrum的錢包地址都可以參與ETHEROOT，僅需要使用錢包地址進行連接簽名，簽名過程中無需支付任何gas費用！ 該簽名僅代表登入，Etheroot簽名採用區塊鏈標準簽名管道，過程中沒有任何涉及您的地址的許可權濫用和私密金鑰洩露的安全問題，請放心使用！</p>
              <p>如果您還不放心，我們也歡迎您尋求全球任何科技安全顧問查看Etheroot的簽名過程，您可以在收到確認後再進行參與。 ETHEROOT接受全球科技專家的安全稽核，完全確保我們的DEFI安全性。</p>`,
        t23: 'FAQ',
        t24: '領取記錄',
        t25: '領取時間',
        t26: '已領取數量',
        t27: '已領取總量',
        t28: 'OTC評估價'
    },
    discount: {
        t0: '折扣',
        t1: '每個月ETHEROOT平臺都會分期篩選出全球漲幅最佳最熱門幣種。 NFT俱樂部會員可以進行免費領取，也可以參與折扣申購。 會員首次參與可以穩定獲利50%+。 跟隨ETHEROOT，找到最新鮮和最熱加密資產。'
    },
    footer: {
        t0: '關於我們',
        t2: 'ETHEROOT是由全球以太坊資深開發者發起的社區資產創造平臺。 ETHEROOT運行在Ethereum Layer2 Arbitrum和主網上，為Digital資產發行服務。 我們提供了AIGC工具，ERC721NFT生產鑄造工具，ERC20發行工具。 在這裡你可以挖掘到全球最具潜力的社區資產。'
    }
}
