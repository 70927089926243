export default {
    common: {
        t0: 'Operation successful ',
        t1: 'Details ',
        t2: 'Mint ',
        t3: 'Select all ',
        t4: 'Picture ',
        t5: 'Please enter prompt word ',
        t6: 'Please select scale ',
        t7: 'Created successfully',
        t8: 'Connect Wallet',
        t9: 'Please connect wallet',
        t10: 'Confirm',
        t11: 'Cancel',
        t12: 'ETHEROOT focused on the Ethereum Asian WEB3 project Launch, ARB DEFI ranked first. NFT Launch, AIGC art Launch , MEME Launch, PREIEO round Lauch, the opportunity is Launched to the right person.',
        t13: '<p>ETHEROOT family invitation letter</p><p>connect wallet to confirm friendship</p>',
        t14: '"Copy the invitation link and forward it to friends,You will get a large number of platform rewards."'
    },
    header: {
        t0: 'Home',
        t1: 'Membership',
        t2: 'Project ',
        t3: '3D',
        t4: 'AIGC ',
        t5: 'Mine ',
        t6: 'Invite',
        t7: 'FREE'
    },
    home: {
        t0: 'ETHEROOT ',
        t1: 'The top AI & Blockchain application platform in Web3 network ',
        t2: 'Membership',
        t3: 'Launch Pad ',
        t4: 'AI Painting ',
        t5: '1. Participate in project <br/>2. Get token airdrop <br/>3. Experience AI painting ',
        t6: '1. Free participation in the new platform <br/>2.NFT+token multiple rewards ',
        t7: '1. Advanced AI painting engine <br/>2. No limit on the number of paintings ',
        t8: 'Go to ',
        t9: 'Roadmap ',
        t10: 'Platform Startup ',
        t11: 'Open the Pass membership function <br/> Open the MEME claim function ',
        t12: 'Launch NFT Sales market ',
        t13: 'Multi-Token airdrop <br/> Open Community Governance ',
        t14: '2023 Q2 ',
        t15: '2023 Q3 ',
        t16: '2023 Q4 ',
        t17: '2024 Q1',
        t18: '<p>In response to HK New policy and to fully support the development of web3.0, Etheroot project was jointly launched by worldwide Web3 elites. The project is based on ETH\'s most advanced Layer2  DEFI technology, while applying the AIGC technology, the perfect combination of Crypto, NFT and Blockchain. It not only gives full play to the greatest advantages of ETH, but also lays a solid foundation for the diversification, long-term and large-scale development of projects.</p>' +
            '<p>The project fully integrates Ethereum EVM and layer 2 network, transparent account privacy protocol, decentralized NFT account system, perfect layer 2 network toolkit, one-click creation of exclusive digital assets, trusted transaction proof, and support for access and use via all kinds of wallets.</p>' +
            '<p>The initial stage of the project has received high attention from Web3 world and the Ethereum ecological , and has attracted capital injection and support by several whale addresses. We will do our best to seize the commanding heights of the Web 3.0 and crypto industries in HK and contribute our effort.</p>'
    },
    pass: {
        t0: 'unit price ',
        t1: 'Rule description ',
        t2: 'open in',
        t3: 'opening',
        t4: 'Upcoming',
        t5: 'end',
        t6: 'EtheRootMemeClub Membership NFT（ERM）',
        t7: '<p>ERM is a digital art NFT series issued by Etheroot. As the sole identifier of Etheroot membership NFT, it offers limitless possibilities for participating in meme projects from this platform, as well as enjoying various platform benefits. </p>' +
            '<p>Through holding and stacking ERM, members can enjoy more substantial equity and expected returns. </p>' +
            '<p>Each issue of ERM will be released on schedule, with each issue being priced higher than the previous.</p>' +
            '<p>To acquire and enjoy the full benefits of ERM, one must have more holdings. With the more holdings one has, the greater the value and enjoyment one can expect. </p>' +
            '<p>Additionally, ERM\'s versatility as a digital artwork not only offers a unique investment opportunity, but also adds an artistic element to the world of Web3.</p>' +
            '<p>ERM\'s artistic and versatile nature, coupled with its membership-only benefits, makes it an attractive investment proposition. Acquiring ERM provides limitless possibilities to participate in meme projects and offers a unique investment opportunity through the stacking and holding of these digital artworks.</p>'
    },
    launchpad: {
        t0: 'Open Project ',
        t1: 'About to start ',
        t2: 'Project closed ',
        t3: 'Limit <span>{0} votes per person per day </span>',
        t4: 'Project name ',
        t5: 'Total Token',
        t6: 'Progress',
        t7: 'Project Details ',
        t8: 'Opening Hours ',
        t9: 'Token total ',
        t10: 'End time ',
        t11: 'Total allocation ',
        t12: 'Pick-up time ',
        t13: 'Airdrop or not ',
        t14: 'Airdrop quantity ',
        t15: 'Rate',
        t16: 'Airdrop conditions ',
        t17: 'Team Introduction ',
        t18: 'Contract address ',
        t19: 'Twitter ',
        t20: 'Telegram',
        t21: 'You don\'t have ERM yet? ',
        t22: 'Go to',
        t23: 'Mintable',
        t24: 'Project details',
        t25: 'Opening period',
        t26: 'Total number of tokens',
        t27: 'End time',
        t28: 'Allocated total amount',
        t29: 'Collection time',
        t30: 'Will there be an airdrop?',
        t31: 'NFTs',
        t32: 'Number of airdrops',
        t33: 'Rate',
        t34: 'Air drop conditions',
        t35: 'Team Introduction',
        t36: 'Contract address',
        t37: 'Twitter',
        t38: 'TG',
        t39: 'Yes',
        t40: 'No',
        t41: 'Claimable'
    },
    produce: {
        t0: 'Prompt ',
        t1: 'Different scenarios, order, wording will affect the final result ',
        t2: 'Art Style ',
        t3: 'School of Art ',
        t4: 'Work scale ',
        t5: 'Advanced Settings (optional) ',
        t6: 'Draw',
        t7: 'Enter prompts',
        t8: 'Select a ratio',
        t9: 'Not specified ',
        t10: 'quadratic element ',
        t11: 'Anime Style ',
        t12: 'Portrait ',
        t13: 'Pixel Art ',
        t14: 'Asian Style',
        t15: 'Photography ',
        t16: 'Oil Painting ',
        t17: '3A Painting ',
        t18: 'Cinematic Sense ',
        t19: 'Cyberpunk ',
        t20: 'Pixar ',
        t21: 'Cartoon Rendering ',
        t22: 'Da Vinci ',
        t23: 'Van Gogh ',
        t24: 'Monet ',
        t25: 'Picasso ',
        t26: 'Xu Beihong ',
        t27: 'Hiroshi Nagai ',
        t28: 'Marian North ',
        t29: 'Square ',
        t30: 'Vertical ',
        t31: 'horizontal ',
        t32: 'Image definition ',
        t33: 'Expression Change ',
        t34: 'Advanced Play ',
    },
    account: {
        t0: 'Collected tokens'
    },
    luck: {
        t0: 'Collected',
        t1: 'Fixed Yield',
        t2: 'Day',
        t3: 'Daily Cycle Yield',
        t4: 'Invitation Yield',
        t5: 'Available today',
        t6: 'Collect',
        t7: 'Collected',
        t8: 'Token Introduction',
        t9: 'ETHEROOT Commemorative Token. Proof token for community growth. ETHROOT ecological welfare vouchers, voting, community building, and more.',
        t10: 'Total',
        t11: 'Number of addresses held',
        t12: 'Daily production from Ethereum',
        t13: 'Cost',
        t14: 'Free',
        t15: 'Collect way',
        t16: 'Fixed yield is obtained automatically , click \'Collect\' daily to obtain invitation yield.',
        t17: 'Share',
        t18: 'Sharing links to invite more users can increase daily yield.',
        t19: 'Share Now',
        t20: 'Introduction',
        t21: `<p>Fixed yield is the daily fixed output after the first click on the 'Collect' button, regardless of whether you click daily in the future, your address will receive a fixed output of tokens every day.</p>
              <p>The current fixed production is 100 token per day.</p>
              <p>Daily cycle yield: The amount of additional output that requires daily clicks to obtain. The daily cycle yield is generated after each natural day switch and you need to manually collect at any time of each natural day. If it is not collected on that day, the production will no longer increase and accumulate. You have to click the 'Collect' button,then you get the daily cycle production for 'that' day and re-accumulate the next daily cycle production.</p>
              <p>The current daily cycle production is 100 token per day.</p>
              <p>Example: User Alice accessed Etheroot on day 1 and clicked the 'Collect' button for the first time. On day 2 and day 3, Alice forgets to collect her own token. On day 4, Alice visits the collection page and clicks the Collect button. At this time, Alice collects: the fixed production on day 1,2,3,4, and the daily cycle production on day 4. The daily output is then re-calculated.</p>
              <p>Invitation yield is to share the invitation link of this address to other users, and the user uses your invitation link to access Etheroot and connect the official website using any Ethereum/Arbitrum blockchain wallet, which is recorded as a valid invitation.</p>
              <p>Examples of Invitation yield:</p>
              <p>Alice invited Bob,Bob invited Camel,Camel invited David,David invited Eva,Eva invited Frank,Frank invited George,George invited Hellen.</p>
              <p>Alice's invited production for one day is: 100+20+10+5+4+3+2+1=145.</p>
              <p>Meanwhile</p>
              <p>Alice also invited Bill,Bill invited Celine,Celine invited Daniel,Daniel invited Ember,Ember invited Fisher, Fisher invited Garry,Garry invited Hudson.</p>
              <p>Alice's invited production for one day is 145+145=290.</p>
              <p>The Invitation yield and Daily cycle yield are collected by the same way, and the invitaiton yield can be collected after each natural day switched. If you forget to collect the daily invitation production, invitation mining will be paused until the day invite production is collected again after clicking the Collect button.</p>
              <p>The total number of tokens is 2,015,730,000.</p>
              <p>The initial daily output is 2,015,730.</p>
              <p>After the total daily yield limit of the whole network is reached, the collection will be on a first-come, first-served basis.</p>
              <p>Each time the yield quantity reach 50% of the remaining total, the daily output will be halved.</p>
              <p>Example:</p>
              <p>When 50% of the total network has been collected (1,007,865,000), the maximum daily output of all is halved to 1,007,865.</p>
              <p>When the total amount collected by the whole network reaches 50% of the remaining total (1,511,797,500), the daily output limit is again halved to 503,932.5.</p>`,
        t22: `<p>1. How can I participate</p>
              <p>You need to have an ETH wallet address and access Etheroot using any wallet.</p>
              <p>Select connect wallet to login Etheroot by your current address.</p>
              <p>2. Is it safe for me to use wallet address signature and connect to ETHEROOT?</p>
              <p>Very safe!</p>
              <p>Any ETH,Arbitrum's wallet address can connect with ETHEROOT, only need to use the wallet address for connection signature, signing process without paying any gas fees! The signature only represents login, Etheroot signature adopts the blockchain standard signature, and there is no security problem involving the abuse of permission and private key disclosure of your address in the process, please feel free to use!</p>
              <p>If you are not yet confident, we also welcome you to seek out any technical security consultant worldwide to review Etheroot's signature process, and you can participate after receiving confirmation. ETHEROOT is subject to security audits by technical experts around the world to fully ensure our DEFI security.</p>`,
        t23: 'FAQ',
        t24: 'Collection Record',
        t25: 'Collection Time',
        t26: 'Received quantity',
        t27: 'Amount claimed',
        t28: 'today OTC appraisal price'
    },
    discount: {
        t0: 'Discount',
        t1: 'Each month, ETHEROOT selects the best and hottest coins in the world. NFT Club members can receive a free claim or participate in a discount subscription. Members can make a steady profit of 50%+ for their first participation. Follow etheroot to find the freshest and hottest crypto assets.'
    },
    footer: {
        t0: 'About Us',
        t2: 'ETHEROOT is a community asset creation platform initiated by senior Ethereum developers around the world. ETHEROOT runs on Ethereum Layer2 Arbitrum and the Ethereum mainnet for Web3 startup. We offer AIGC tools, ERC721NFT production mint tools, ERC20 distribution tools. Here you can connect to the world\'s most valuable potential community assets.'
    }
}
