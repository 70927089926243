export function fetch (options = { url: '/', query: null, data: null, method: 'get', responseType: null}) {
    // if (options.data) {
    //     if (typeof options.data === 'object') {
    //         options.data = JSON.parse(JSON.stringify(options.data))
    //     }
    // }
    if (options.method === 'get' || options.method === 'delete' || options.query) {
        options.url = addUrlQuery(options.url, options.query || {})
    }
    return options
}

export function addUrlQuery (url:string, key: any, value?: any, unencode?: any) {
    if (typeof url !== 'string') {
        throw new TypeError('arguments[0] must be a string')
    }
    if (!key) {
        return url
    }
    let originPathname = url
    let search = ''
    let hash = ''
    const searchIndex = url.indexOf('?')
    const hashIndex = url.indexOf('#')
    if (hashIndex > -1) {
        hash = url.slice(hashIndex)
        if (searchIndex > -1) {
            search = url.slice(searchIndex, hashIndex)
            originPathname = url.slice(0, searchIndex)
        } else {
            originPathname = url.slice(0, hashIndex)
        }
    } else if (searchIndex > -1) {
        search = url.slice(searchIndex)
        originPathname = url.slice(0, searchIndex)
    }
    let newSearch
    switch (typeof key) {
        case 'string':
            search += (search ? '&' : '?') + key + '=' + (unencode ? value : encodeURI(value))
            break
        case 'object':
            unencode = value
            newSearch = Object.keys(key).map(k => {
                return unencode ? k + '=' + key[k] : k + '=' + encodeURI(key[k])
            }).join('&')
            if (newSearch) {
                search += (search ? '&' : '?') + newSearch
            }
    }
    return originPathname + search + hash
}
