import { createApp } from 'vue'
import './less/main.less'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import App from './App.vue'
import {router} from './routers'
import { createPinia } from 'pinia'
import {createPersistedState} from 'pinia-plugin-persistedstate'
import { ConfigProvider } from 'vant'
import i18n from './languages'
import BigNumber from "bignumber.js"
import VConsole from 'vconsole'

if (process.env['VUE_APP_NODE_ENV'] !== 'production') {
    const vConsole = new VConsole({ theme: 'dark' })
}

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const pinia = createPinia()
pinia.use(createPersistedState({
    storage: localStorage
}))
const app = createApp(App)

app.use(i18n)
app.use(ConfigProvider)
app.use(pinia)
app.use(router)
app.mount('#app')
