import { fetch } from '../utils/fetch'
export const apis = {
    bindAddress: {
        url: '/h5/bindAddress',
        method: 'post',
        host: process.env.NODE_ENV === 'development' ? '/api' : 'https://api.catopia.io/api'
    },
    isBinded: {
        url: '/h5/checkAddress',
        method: 'get',
        host:  process.env.NODE_ENV === 'development' ? '/api' : 'https://api.catopia.io/api'
    }
}

const WEB_API = {}
let HOST:string = '/api'
if (process.env.NODE_ENV === 'development') {
    HOST = '/api'
}

const listener = (value: { host?: any; url?: any; method?: any }) => {
    const { url, method = 'get' } = value
    const targetHost = value.host !== undefined ? value.host : HOST
    let query: any;
    let data: any;
    return (params: { formatUrl: any; responseType: any }, urlParam: any) => {
        let path = url;
        let responseType
        if (params && params.formatUrl) {
            path = formatUrl(path, params.formatUrl)
            delete params.formatUrl
        }
        method === 'get' ? (query = params) : (data = params)
        if (urlParam) {
            query = urlParam
        }
        if (params && params.responseType) {
            responseType = params.responseType
            delete params.responseType
        }
        return fetch({
            url: targetHost + path,
            query,
            data,
            method,
            responseType
        })
    };
};

function formatUrl(url: string, format: any[]) {
    format.forEach((item, index) => {
        url = url.replace(`{${index}}`, item);
    });
    return url;
}

for (const key in apis) {
    // @ts-ignore
    WEB_API[key] = listener(apis[key]);
}

export {
    WEB_API
}
