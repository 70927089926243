import Web3 from 'web3'
// import Web3Modal from "web3modal"
import {ref, onMounted} from 'vue'
import {mainChainName, netWork} from "@/config";
// import {getOptrions} from '../utils'

let provider: any
const { ethereum } = window as any
if (ethereum == null) {
    console.log('MetaMask not installed; using read-only defaults')
    provider = new Web3.providers.HttpProvider(netWork[mainChainName].rpcUrls[0])
} else {
    provider = ethereum
}

// provider = new Web3.providers.HttpProvider('https://arb1.arbitrum.io/rpc')

// const defaultOptions = {
//     cacheProvider: true,
//     providerOptions: getOptrions()
// }

const web3 = new Web3(provider)

console.log(web3, provider, 99999999)

console.log(provider, 'p')

export const useWeb3 = () => {
    return {web3, provider}
}

export const useProvider = () => {
    return { provider }
}

export const useProviderListener = (ev:string, cb: (v: any) => void) => {
    console.log(provider, 'p111111')
    provider.on(ev, cb)
}

export const useUtils = () => {
    return [web3.utils]
}

// export const useConnectWallet = () => {
    // const web3Modal = new Web3Modal(defaultOptions)
    // const address = ref<any>('')
    // const connect = async (id:any) => {
    //     provider = await web3Modal.connectTo(id)
    //     const web3 = new Web3(provider)
    //     const accounts = await web3.eth.getAccounts()
    //     return accounts[0]
    // }
    // return [address, connect]
// }

export const useConnectWallet = () => {
    const address = ref<any>('')
    const connect = async () => {
        const accounts = await web3.eth.requestAccounts().then((res:any) => res).catch((err:any) => err)
        console.log(accounts, 7777766666)
        address.value = accounts[0]
        return accounts[0]
    }
    return [address, connect]
}

export const usePersonalSign = () => {
    const onSign = async (account: string, message: string) => {
        await provider.request({
            method: 'eth_requestAccounts',
        })
        console.log(account, 88888887777)
        const signature = await provider.request({
            method: 'personal_sign',
            params: [
                web3.utils.utf8ToHex(message),
                account
            ],
            form: account
        })
        return signature
    }
    return {onSign}
}

export const useBalance = () => {
    const getBalance = (address:string) => {
        return web3.eth.getBalance(address)
    }
    return { getBalance }
}

export const useContract = (address: string, abi: any[], events?: any[]) => {
    const contract = new web3.eth.Contract(abi, address)
    const call = (method: string, params?: any[], options?: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return contract.methods[method](...(params || [])).call(options || {}).then((res:any) => res).catch((err:any) => err)
    }
    const send = (method: string, params?: any[], options?: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return contract.methods[method](...(params || [])).send(options || {}).then((res:any) => res).catch((err:any) => err)
    }
    const evts: {[key:string]: any} = {}
    if (events) {
        events.forEach((item: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            evts[item] = contract.events[item]({}, () => {})
        })
    }
    return [call, send, evts]
}

export const useTransactionReceipt = () => {
    const receipt = async (hash: string, cp?: () => void) => {
        const res = await web3.eth.getTransactionReceipt(hash)
        if (!res.status) {
            setTimeout(() => {
                receipt(hash, cp)
            }, 3000)
        } else {
            cp && cp()
        }
    }
    return [receipt]
}

export const useAddTokenToWallet = () => {
    const addToken = ({address, symbol, image}: {address: string, symbol?: string, image?: string}) => {
        const params = {
            type: 'ERC20',
            options: {
                address,
                symbol,
                decimals: 18
            }
        }
        console.log(params)
        provider.request({
            method: 'wallet_watchAsset',
            params
        }).then((res:any) => res).catch((err:any) => err)
    }
    return {addToken}
}

export const useChain = () => {
    const addChain = (chainName:string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const params = [netWork[chainName]]
        return provider.request({
            method: 'wallet_addEthereumChain',
            params
        }).then((res:any) => res).catch((err:any) => err)
    }
    const switchChain = (chainId:string) => {
        return provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId}]
        }).then((res:any) => res).catch((err:any) => err)
    }
    return { addChain, switchChain }
}
