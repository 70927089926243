import { createI18n } from 'vue-i18n'
import cn from './zh'
import en from './en'
import kr from './kr'
import hk from './hk'

const messages = {
    en,
    cn,
    kr,
    hk
};

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: localStorage.getItem('lang') || 'en', // 首先从缓存里拿，没有的话就用浏览器语言，
    fallbackLocale: 'en', // 设置备用语言
    messages,
});

export default i18n
