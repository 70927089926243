export const whitePaper = {
    1: {
        title: 'Phase 1',
        id: 1,
        subTitle: '',
        info: `<div>
                <p>Closed Beta (Q2 2023) 'Catopia' will be introduced for players to experience simple interaction with virtual pets.</p>
                <p>All players can download and register for free to participate in the game's gold-making process.</p>
             </div>`
    },
    2: {
        title: 'Phase 2',
        subTitle: '',
        id: 2,
        info: `<div>
                <p>Public Beta (Q3 2023) After entering the public beta, all NFT assets in the game will be allowed to be traded or staked on the blockchain. </p>
                <p>COA generated by players in the game can also be traded or staked on the chain. </p>
                <p>In addition to common white cats, higher-tier NFT cats such as limited, rare, epic, and legendary ones will also be available to players. </p>
                <p>We will announce the economic model of 'Catopia’.</p>
              </div>`
    },
    3: {
        title: 'Phase 3',
        subTitle: '',
        id: 3,
        info: `<div>
                <p>Official Release (Q2 2024) We will release the first pet-focused artificial intelligence application, CatGPT, which uses AIGC to give pets intelligence and emotions. </p>
                <p>Through machine algorithms and deep learning, pets can understand player commands, express their own intentions, and generate emotional feedback. </p>
                <p>At the same time, we will train virtual pets to learn new skills and behaviors, giving them higher intelligence and flexibility.</p>
               </div>`
    },
    4: {
        title: 'Phase 4',
        subTitle: '',
        id: 4,
        info: `<div>
                <p>'Catopia' 2.0 (Q1 2025) 'Catopia' will open up corresponding gameplay/content production modules and open-source production processes for global developers, content creators, and ordinary players to create new stories and experiences within the metaverse ecosystem of 'Catopia'.</p> 
                <p>Developers can create more game scenes or create new AIGC pet games based on the CatGPT SDK. </p>
                <p>At the same time, COA will become the core circulation currency across games, platforms, and gameplay.</p>
               </div>`
    }
}
