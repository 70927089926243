import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import mainLayout from './layouts/main';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_van_config_provider = _resolveComponent("van-config-provider");
      return _openBlock(), _createBlock(_component_van_config_provider, {
        theme: "dark"
      }, {
        default: _withCtx(() => [_createVNode(_unref(mainLayout))]),
        _: 1
      });
    };
  }
};