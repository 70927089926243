import { defineStore } from 'pinia'

// @ts-ignore
export const useGlobalStore = defineStore('global', {
  state: () => ({
    account: '',
    authorization: '',
    serverTimeDiff: 0,
    chainId: '',
    invite: '',
    visibleWalletModal: false,
    network: 1
  }),
  actions: {
    setAccount(account:any) {
      this.account = account
    },
    setAuthorization(authorization: string) {
      this.authorization = authorization
    },
    setServerTimeDiff(time: number) {
      this.serverTimeDiff = time
    },
    setChainId(chainId: string) {
      this.chainId = chainId
    },
    setInvite(invite: string) {
      this.invite = invite
    },
    setVisibleWalletModal(v: boolean) {
      this.visibleWalletModal = v
    },
    setNetWork(v:any) {
      this.network = v
    }
  },
  persist: true
})
