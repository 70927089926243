export default {
    common: {
        t0: '작업이 성공했습니다.',
        t1:'상세한 상황',
        t2: '주조',
        t3: '모두 선택',
        t4: '이미지',
        t5: '힌트를 입력해주세요.',
        t6: '작품 비율을 선택하세요.',
        t7: '성공적으로 만들기',
        t8: '지갑 연결',
        t9: '지갑 연결해주세요.',
        t10: '확인',
        t11: '취소',
        t12: 'ETHEROOT은 이더리움 아시아 WEB3 프로젝트의 시작에 집중했으며 ARB DEFI가 1 위를 차지했습니다.NFT 발표회, AIGC 예술 발표회, MEME 발표회, PREIEO 라운드 발표회, 기회는 적합한 사람에게 있다.',
        t13: '<p>ETHEROOT 가족 초대장</p><p>지갑 연결해야 친구관계 확인</p>',
        t14: '"초대 링크를 복사하여 친구에게 전달합니다.대량의 플랫폼 보상을 획득 가능"'
    },
    header: {
        t0:"홈페이지",
        t1:'회원',
        t2:"프로젝트",
        t3:'3D',
        t4:'AIGC',
        t5:"내 것",
        t6: '초대',
        t7: '무료'
    },
    home: {
        t0:'ETHEROOT',
        t1:'전체 네트워크 최초의 AI와 블록체인이 결합된 응용 플랫폼',
        t2: '회원',
        t3:'프로젝트 시작 플랫폼',
        t4:'AI 그림',
        t5:'1. 플랫폼 프로젝트 참여 <br/>2. token 공중 투하 획득 <br/>3. AI 회화 체험',
        t6:'1. 무료로 참여하는 신규 플랫폼 <br/>2. NFT+token 다양한 보상',
        t7:'1. 첨단 AI 회화 엔진 <br/>2. 회화 횟수 제한 없음',
        t8:'가면',
        t9:'로드맵',
        t10:'플랫폼 기초 능력 구축',
        t11:'Pass 회원 기능 개통<br/> MEME 발행 기능 오픈',
        t12:'온라인 NFT 발매 시장',
        t13:'다양한 token 공중 투하<br/> 열린 커뮤니티 거버넌스',
        t14:'2023 2분기',
        t15:'2023 3분기',
        t16:'2023 4분기',
        t17:'2024 1분기',
    },
    pass: {
        t0:'단가',
        t1:'규칙 설명',
        t2:'열기 in',
        t3:'개방',
        t4:'다가오는',
        t5:'끝',
        t6: 'EtheRootMemeClub Membership NFT（ERM）',
        t7: '<p>ERM은 Etheroot에서 발행하는 디지털 art NFT 시리즈입니다.Etheroot 멤버십 NFT의 유일한 식별자로서이 플랫폼에서 meme 프로젝트에 참여할 수 있는 무한한 가능성을 제공할 뿐 아니라 다양한 플랫폼 혜택을 누릴 수 있다.</p>' +
            '<p>ERM 보유와 쌓기를 통해 회원들은보다 실질적인 자기자본과 기대수익을 누릴 수 있다.</p>' +
            '<p>ERM의 각 호는 일정대로 발매될 예정이며, 각 호의 가격은 기존보다 높게 책정되었다.</p>' +
            '<p>ERM의 완전한 혜택을 획득하고 누리기 위해서는 더 많은 지분을 보유해야 한다.보유량이 많을수록 더 큰 가치와 즐거움을 기대할 수 있다.</p>' +
            '<p>또한 ERM의 디지털 아트워크로서의 다재다능함은 독특한 투자 기회를 제공할 뿐만 아니라 Web3의 세계에 예술적인 요소를 더해준다.</p>' +
            '<p>ERM의 예술적이고 다재다능한 성격에 회원 전용 혜택까지 더해져 매력적인 투자 제안으로 꼽힌다.ERM을 인수하는 것은 밈적 프로젝트에 참여할 수 있는 무한한 가능성을 제공하고 이러한 디지털 아트웍의 쌓기와 보유를 통해 독특한 투자 기회를 제공한다.</p>'
    },
    launchpad: {
        t0:'오픈 프로젝트',
        t1:'막 시작하려 한다',
        t2:'프로젝트 종료',
        t3:'1인당 하루 {0} 표 제한',
        t4:'프로젝트명',
        t5:'토탈 토큰',
        t6:'진전',
        t7:'프로젝트 세부사항',
        t8:'영업시간',
        t9:'토큰 총량',
        t10:'종료 시간',
        t11:'총 할당',
        t12:'픽업 타임',
        t13:'에어드랍 여부',
        t14:'에어드롭 수량',
        t15:'속도',
        t16:'에어드랍 조건',
        t17:'팀 소개',
        t18:'계약 주소',
        t19: 'Twitter ',
        t20: 'Telegraph',
        t21:'너 아직 ERM 없어?',
        t22:'가다',
        t23:'사용 가능 한',
        t24:'프로젝트 상세 정보',
        t25:'열린 시간',
        t26:'Token 총량',
        t27:'종료 시간',
        t28:'할당 총량',
        t29:'수령 시간',
        t30:'공중 투하 여부',
        t31: 'NFTs',
        t32:'공중 투하 수량',
        t33: 'Rate',
        t34:'공중 투하 조건',
        t35:'팀 소개',
        t36:'계약 주소',
        t37:'트위터',
        t38: 'TG',
        t39:'예',
        t40:'아니오',
        t41:'수령 가능'
    },
    produce: {
        t0: '프롬프트',
        t1: '다른 시나리오, 순서, 문구가 최종 결과에 영향을 미칠 것',
        t2: '아트 스타일',
        t3: '예술학교',
        t4: '작업척도',
        t5: '고급 설정 (옵션)',
        t6: '제작 ',
        t7: '제시어를 입력하십시오. ',
        t8: '축척을 선택하십시오.',
        t9: 'Not specified ',
        t10: 'quadratic element ',
        t11: 'Anime Style ',
        t12: 'Portrait ',
        t13: 'Pixel Art ',
        t14: 'Asian Style',
        t15: 'Photography ',
        t16: 'Oil Painting ',
        t17: '3A Painting ',
        t18: 'Cinematic Sense ',
        t19: 'Cyberpunk ',
        t20: 'Pixar ',
        t21: 'Cartoon Rendering ',
        t22: 'Da Vinci ',
        t23: 'Van Gogh ',
        t24: 'Monet ',
        t25: 'Picasso ',
        t26: 'Xu Beihong ',
        t27: 'Hiroshi Nagai ',
        t28: 'Marian North ',
        t29: 'Square ',
        t30: 'Vertical ',
        t31: 'horizontal ',
        t32: 'Image definition ',
        t33: 'Expression Change ',
        t34: 'Advanced Play ',
    },
    account: {
        t0: 'Collected tokens'
    },
    luck: {
        t0:'수집됨',
        t1:'고정수익률',
        t2:'하늘',
        t3:'일 순환 수익률',
        t4: "스텔스 이익",
        t5:'오늘 사용 가능',
        t6:'수집',
        t7:'수집',
        t8:'토큰 소개',
        t9:'이더리움 기념주화.지역사회 성장의 증명.ETHROOT 생태복지쿠폰, 투표, 커뮤니티 조성 등',
        t10:'통계',
        t11:'유지된 주소 수',
        t12:'전체 네트워크의 일상적인 생산',
        t13:'비용',
        t14: "자유",
        t15:'수집 방법',
        t16:'자동으로 고정 수익을 획득하고, 매일\'즐겨찾기\'를 클릭하여 초대 수익을 획득합니다.',
        t17:'공유',
        t18: "더 많은 사용자를 초대하기 위해 링크를 공유하면 일일 수익을 높일 수 있습니다.",
        t19:'지금 공유',
        t20: "소개",
        t21: `<p>고정 생산량은 처음 획득 버튼을 클릭한 후, 매일 고정 생산량이며, 당신이 미래에 매일 클릭하든 말든, 당신의 주소는 매일 고정 생산량의 Token을 받을 것입니다.</p>
              <p>현재 고정 생산량은 매일 100매이다.</p>
              <p>일일 주기 생산량은 매일 클릭해서 획득해야만 얻을 수 있는 추가 생산량이다.일일 주기 생산량은 매 자연일이 전환된 후에 발생하며, 당신은 매 자연일의 임의의 시간에 수동으로 채집해야 합니다.만약 당일 채집이 없다면, 이 생산량은 더 이상 증가하거나 축적되지 않을 것이다.다음 번 가져오기 버튼을 클릭한 후에야 당일 일별 생산량을 얻을 수 있으며 다음 일별 생산량을 다시 축적할 수 있습니다.</p>
              <p>현재 일주기 생산량은 매일 100매이다.</p>
              <p>예: 사용자 Alice는 첫날에 Etheroot에 액세스하고 처음으로 채집 버튼을 클릭합니다.앨리스는 다음 날, 셋째 날 자신의 광산을 수집하는 것을 잊었고, 넷째 날 앨리스는 수집 페이지에 액세스하여 수집 버튼을 눌렀는데, 이때 앨리스는 1, 2, 3, 4일의 고정 생산량과 4일의 일일 주기 생산량을 수집했다.이후 하루 생산량은 다시 계산하기 시작했다.</p>
              <p>초대장 생산량은 이 주소를 공유하는 초대장을 다른 사용자에게 링크하고, 사용자는 귀하의 초대장 링크를 사용하여 Etheroot에 액세스하고, 임의의 이더넷 블록체인 지갑을 사용하여 공식 홈페이지에 로그인한 후 유효한 초대장으로 기록됩니다.</p>
              <p>초대 생산량 예:</p>
              <p>Alice는 Bob, Bob은 Camel, Camel은 David, David는 Eva, Eva는 Frank, Frank는 George, George는 Hellen을 초대했습니다.</p>
              <p>Alice의 당일 초대 생산량은 100+20+10+5+4+3+2+1=145입니다.</p>
              <p>만약</p>
              <p>Alice는 Bill, Bill은 Celine, Celine은 Daniel, Daniel은 Ember, Ember는 Fisher, Fisher는 Garry, Garry는 Hudson을 초대했습니다.</p>
              <p>앨리스의 당일 초대 생산량은 145+145=290이다.</p>
              <p>초청 생산량과 일주기 생산량 채집 방식이 일치하며, 초청 생산량은 매 자연일 전환 후 채집할 수 있다.당일 초청 생산량을 수령하는 것을 잊어버리면 초청 채광은 채집 버튼을 다시 클릭한 후 당일 초청 생산량을 다시 수집할 때까지 중단된다.</p>
              <p>총 토큰 개수는 2,015,730,000개입니다.</p>
              <p>초기 일일 생산량은 2,015,730개.</p>
              <p>전체 네트워크의 일일 총 생산량 제한에 도달한 후, 선착순으로 수금이 진행됩니다.</p>
              <p>생산량 수량이 나머지 총량의 50%에 달할 때마다 일일 생산량은 반으로 줄어들 것이다.</p>
              <p>예:</p>
              <p>전체 네트워크의 50% 가 모였을 때 (1,007,865,000) 네트워크의 일일 최대 생산량은 1,007,865로 절반으로 줄어든다.</p>
              <p>전체 네트워크가 모은 총량이 나머지 총량의 50% (1,511,797,500)에 도달하면 전체 네트워크의 일일 생산 한도는 다시 503,932.5로 절반으로 줄어든다.</p>`,
        t22: `<p>1.어떤 방법으로 참여해야 합니까?</p>
              <p>모든 지갑 소프트웨어를 사용하여 Etheroot에 액세스하려면 ETH 지갑 주소가 필요합니다.</p>
              <p>Etheroot에 현재 주소로 로그인하려면 connect wallet 을 선택합니다.</p>
              <p>2. 지갑 주소 서명을 사용하여 ETHEROOT에 연결했는데 안전합니까?</p>
              <p>매우 안전합니다!</p>
              <p>ETH, Arbitrum의 지갑 주소는 ETHEROOT에 참여할 수 있으며 지갑 주소를 사용하여 연결 서명만 하면 되며 서명 과정에서 gas 비용을 지불할 필요가 없습니다!이 서명은 로그인만을 나타냅니다. Etheroot 서명은 블록체인 표준 서명 방식을 사용합니다. 이 과정에서 귀하의 주소의 권한 남용과 개인 키 유출과 관련된 보안 문제가 없습니다. 안심하고 사용하십시오!</p>
              <p>안심할 수 없는 상황이라면 전 세계 모든 기술 보안 컨설턴트에게 Etheroot의 서명 프로세스를 검토하도록 요청하는 것을 환영하며, 확인을 받은 후 참여할 수 있습니다.ETHEROOT은 전 세계 기술 전문가의 보안 감사를 받아 DEFI 보안을 완벽하게 보장합니다.</p>`,
        t23: 'FAQ',
        t24:'수령 기록',
        t25:'수령 시간',
        t26:'수령 수량',
        t27: '수령한 총 수량',
        t28: '오늘 OTC 감정 가격'
    },
    discount: {
        t0: '할인',
        t1: '매달 ETHEROOT 플랫폼은 전 세계에서 상승폭이 가장 좋고 가장 인기 있는 화폐를 분기별로 선별한다.NFT클럽 회원은 무료 수령을 하거나 할인 청약에 참여할 수 있다.회원이 처음 참여하면 안정적으로 50% + 이익을 얻을 수 있습니다.ETHEROOT를 사용하여 가장 신선하고 핫한 암호화 자산을 찾으십시오.'
    },
    footer: {
        t0: 'About Us',
        t2: '이더우트 (ETHEROOT)는 전 세계 이더리움 개발자들의 공동 자산 개발 플랫폼이다.ETHEROOT은 이더리움 layer2 arbitrum과 이더리움 메인넷에서 실행되며 디지털 자산 발행을 서비스한다.AIGC 도구, erc721nft 프로덕션 주조 도구, erc20발행 도구를 제공하고 있습니다.세계에서 가장 가치 있는 잠재력 있는 지역 사회와 연결할 수 있습니다.'
    }
}
