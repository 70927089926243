export default {
    common: {
        t0: '操作成功',
        t1: '详情',
        t2: '铸造',
        t3: '全选',
        t4: '图片',
        t5: '请输入提示词',
        t6: '请选择作品比例',
        t7: '创建成功',
        t8: '连接钱包',
        t9: '请连接钱包',
        t10: '确认',
        t11: '取消',
        t12: 'ETHEROOT专注于以太坊亚洲WEB3项目首发打新，ARB网DEFI第一名。NFT首发，AIGC艺术首发，MEME首发，PREIEO轮首发，机遇对了人就发了。',
        t13: '<p>家族邀请函</p><p>连接钱包才能确认好友关系</p>',
        t14: '"复制邀请链接并转发给朋友，可获得大量平台奖励"'
    },
    header: {
        t0: '首页',
        t1: 'Membership',
        t2: '项目',
        t3: '3D',
        t4: 'AIGC',
        t5: '我的',
        t6: '邀请',
        t7: '免费领'
    },
    home: {
        t0: '以太本源',
        t1: '全网第一个AI与区块链结合的应用平台',
        t2: 'Membership',
        t3: '项目启动平台',
        t4: 'AI绘画',
        t5: '1.参与平台项目<br/>2.获得token空投<br/>3.体验AI绘画',
        t6: '1.免费参与的打新平台<br/>2.NFT+token多种奖励',
        t7: '1.先进的AI绘画引擎<br/>2.不限制绘画次数',
        t8: '前往',
        t9: '路线图',
        t10: '平台基础能力搭建',
        t11: '开通交易功能<br/>增加流动性质押功能',
        t12: '上线NFT 交易市场',
        t13: '平台token空投<br/>开放社区治理',
        t14: '2023二季度',
        t15: '2023三季度',
        t16: '2023四季度',
        t17: '2024一季度',
    },
    pass: {
        t0: '单价',
        t1: '规则说明',
        t2: 'open in',
        t3: 'opening',
        t4: 'Upcoming',
        t5: 'end',
        t6: 'EtheRootMemeClub Membership NFT（ERM）'

    },
    launchpad: {
        t0: '开放项目',
        t1: '即将开始',
        t2: '项目关闭',
        t3: '每人每天票数限制 <span>{0}票</span>',
        t4: '项目名称',
        t5: 'Total Token',
        t6: 'Progress',
        t7: '项目详情',
        t8: '开放时间',
        t9: 'Token总量',
        t10: '结束时间',
        t11: '分配总量',
        t12: '领取时间',
        t13: '是否空投',
        t14: '空投数量',
        t15: 'Rate',
        t16: '空投条件',
        t17: '团队介绍',
        t18: '合约地址',
        t19: '推特',
        t20: 'Tele',
        t21: '您还没有ERM？',
        t22: '立即前往',
        t23: '可铸造',
        t24: '项目详情',
        t25: '开放时间',
        t26: 'Token总量',
        t27: '结束时间',
        t28: '分配总量',
        t29: '领取时间',
        t30: '是否空投',
        t31: 'NFTs',
        t32: '空投数量',
        t33: 'Rate',
        t34: '空投条件',
        t35: '团队介绍',
        t36: '合约地址',
        t37: '推特',
        t38: 'Tele',
        t39: '是',
        t40: '否',
        t41: '可领取'
    },
    produce: {
        t0: '描述词',
        t1: '不同场景，顺序，措辞会影响最终结果',
        t2: '艺术风格',
        t3: '艺术流派',
        t4: '作品比例',
        t5: '高级设置（可选）',
        t6: '创作',
        t7: '请输入提示词',
        t8: '请选择作品比例',
        t9: '不指定',
        t10: '二次元',
        t11: '动漫风',
        t12: '肖像',
        t13: '像素艺术',
        t14: '亚洲风',
        t15: '摄影',
        t16: '油画',
        t17: '3A画作',
        t18: '电影感',
        t19: '赛博朋克',
        t20: '皮克斯',
        t21: '卡通渲染',
        t22: '达芬奇',
        t23: '梵高',
        t24: '莫奈',
        t25: '毕加索',
        t26: '徐悲鸿',
        t27: '永井博',
        t28: '玛丽安北',
        t29: '方图',
        t30: '竖图',
        t31: '横图',
        t32: '出图清晰度',
        t33: '表情变化',
        t34: '进阶玩法',
    },
    account: {
        t0: '累计领取token'
    },
    luck: {
        t0: '已领取',
        t1: '固定产量',
        t2: '天',
        t3: '日周期产量',
        t4: '邀请产量',
        t5: '今日可领取',
        t6: '领取',
        t7: '已领取',
        t8: '币种介绍',
        t9: 'ETHEROOT Commemorative Token 社区壮大的证明代币。ETHEROOT生态福利领取凭证，投票，社区建设及更多。',
        t10: '总量',
        t11: '持有地址数量',
        t12: '全网日产量',
        t13: '费用',
        t14: '免费',
        t15: '领取方式',
        t16: '自动获得固定产量，每日点击获得邀请产量',
        t17: '分享',
        t18: '分享链接邀请更多用户可提高日产量',
        t19: '立即分享',
        t20: '说明',
        t21: `<p>固定产量为首次点击获取按钮后，每日固定的产出，无论您未来是否每日点击，您的地址都会每日接收到固定产量的Token。 当前固定产量为100枚每天。</p>
              <p>日周期产量需要每日点击获取，才能获得的额外产出量。日周期产量在每个自然日切换后产生，您需要每个自然日任意时间手动采集。如果当日没有采集，则该产量不再增加和积累。您必须在下一次点击获取按钮之后，才获得当天的日周期产量，并且重新积累下一个日周期产量。</p>
              <p>当前日周期产量为100枚每天。</p>
              <p>举例：用户Alice在第一天访问了Etheroot，并且首次点击采集按钮。Alice在第二天，第三天都忘记收集自己的矿产，第四天Alice访问了收集页，并点击了收集按钮,此时Alice收集到：第1,2,3,4天的固定产量和第4天的日周期产量。之后日产量重新开始计算。</p>
              <p>邀请产量为分享本地址的邀请链接给其他用户，用户使用您的邀请链接访问Etheroot并使用任意以太网区块链钱包登录官网后，记录为一次有效邀请。</p>
              <p>邀请产量举例：</p>
              <p>Alice邀请了Bob,Bob邀请了Camel,Camel邀请了David,David邀请了Eva,Eva邀请了Frank,Frank邀请了George,George邀请了Hellen.</p>
              <p>Alice当日的邀请产量为：100+20+10+5+4+3+2+1=145。</p>
              <p>如果 Alice还邀请了Bill,Bill邀请了Celine,Celine邀请了Daniel,Daniel邀请了Ember,Ember邀请了Fisher，Fisher邀请了Garry,Garry邀请了Hudson.</p>
              <p>Alice当日的邀请产量为：145+145=290。</p>
              <p>邀请产量和日周期产量采集方式一致，邀请产量在每个自然日切换后可以采集。当日邀请产量如果忘记领取，邀请采矿将暂停，直至再次点击采集按钮后重新收集当日邀请产量。</p>
              <p>初始全网日产量上限2,015,730枚</p>
              <p>在全网日总产量上限达到后，每日领取先到先得。</p>
              <p>已采集量每次达到剩余总量的50%时，全网日产量减半。</p>
              <p>示例：</p>
              <p>当全网已采集量达到总量50%(1,007,865,000)时，全网日产量上限减半至1,007,865枚。</p>
              <p>当全网已采集量达到剩余总量的50%时（1,511,797,500）时，全网日产量上限再次减半至503,932.5枚。</p>`,
        t22: `<p>1.我需要使用什么方式参与？</p>
              <p>您需要拥有一个ETH钱包地址，使用任意钱包软件访问Etheroot。</p>
              <p>选择connect wallet 以当前地址登录Etheroot。</p>
              <p>2.我使用钱包地址签名连接ETHEROOT，安全吗？</p>
              <p>非常安全！</p>
              <p>任何ETH,Arbitrum的钱包地址都可以参与ETHEROOT，仅需要使用钱包地址进行连接签名，签名过程中无需支付任何gas费用! 该签名仅代表登录，Etheroot签名采用区块链标准签名方式，过程中没有任何涉及您的地址的权限滥用和私钥泄露的安全问题，请放心使用！</p>
              <p>如果您还不放心，我们也欢迎您寻求全球任何技术安全顾问查看Etheroot的签名过程，您可以在收到确认后再进行参与。ETHEROOT接受全球技术专家的安全审核，完全确保我们的DEFI安全性。</p>`,
        t23: 'FAQ',
        t24: '领取记录',
        t25: '领取时间',
        t26: '已领取数量',
        t27: '已领取总量',
        t28: 'OTC评估价格'
    },
    discount: {
        t0: '折扣',
        t1: '每月平台都会筛选出全球涨幅最佳最热门币种，NFT俱乐部会员可以进行免费领取或折扣申购。会员首次参与可以稳定获利50%+。跟随etheroot，找到最新最热资产。'
    },
    footer: {
        t0: '关于我们',
        t2: 'ETHEROOT是由全球以太坊资深开发者发起的社区资产创造平台。ETHEROOT运行在以太坊Layer2 Arbitrum和以太坊主网上，为数字资产发行服务。我们提供了AIGC工具，ERC721NFT生产铸造工具，ERC20发行工具。在这里你可以挖掘到全球最具潜力的社区资产。'
    }
}
